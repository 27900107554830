import { computed } from 'vue';

export const procedureEmits = ['insertChild', 'updateChild', 'selectionOnChild'];
export const procedureProps = {
	model: { type: Object, required: true },
	path: { type: String, required: true },
	parentNumber: { type: String, default: null },
	number: { type: String, required: true },
	username: { type: String, required: true },
	versionViewer: { type: Boolean, default: false },
	procedureID: { type: String, required: true },
};

export default function useProcedureElement(props, emits) {
	const model = computed(() => props.model);
	const path = computed(() => props.path);
	const number = computed(() => props.number);
	const parentNumber = computed(() => props.parentNumber);
	const username = computed(() => props.username);
	const versionViewer = computed(() => props.versionViewer);
	const procedureID = computed(() => props.procedureID);
	const insertChild = (action) => {
		emits('insertChild', action);
	};

	const updateChild = (action) => {
		emits('updateChild', action);
	};

	const createAndEmitUpdateAction = (localPath, newValue) => {
		const action = {
			patch: {
				op: 'replace',
				path: `${props.path}/${localPath}`,
				value: newValue,
			},
			metadata: {
				author: props.username,
				clientCreationAt: new Date().toISOString(),
			},
		};
		updateChild(action);
	};

	const selectionOnChild = (action) => {
		emits('selectionOnChild', action);
	};

	const createAndEmitSelectionAction = ({ startSelectionPosition, endSelectionPosition }) => {
		const action = {
			patch: {
				op: 'replace',
				path: `${props.path}/selection`,
				value: {
					startSelectionPosition,
					endSelectionPosition,
					author: props.username,
				},
			},
			metadata: {
				author: props.username,
				clientCreationAt: new Date().toISOString(),
			},
		};
		selectionOnChild(action);
	};

	const getNumberLabel = () => {
		if (props.parentNumber === null) {
			return `${props.number}`;
		}
		return `${props.parentNumber}.${props.number}`;
	};

	return {
		model,
		path,
		number,
		parentNumber,
		username,
		versionViewer,
		procedureID,
		getNumberLabel,
		insertChild,
		updateChild,
		selectionOnChild,
		createAndEmitUpdateAction,
		createAndEmitSelectionAction,
	};
}

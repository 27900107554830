import { io } from 'socket.io-client';

export default class ProcedureAPI {
	static #getProcedureUrl(procedureID) {
		return `/api/authoredProcedures/${procedureID}`;
	}

	static async fetchProcedureDetails(procedureID) {
		const response = await fetch(this.#getProcedureUrl(procedureID));
		if (!response.ok) {
			throw new Error(`Failed to fetch procedure details ${response.statusText}`);
		}
		const procedure = await response.json();
		console.debug('📥 Fetched procedure details:', procedure);
		return procedure;
	}

	static async fetchProcedureVersion(procedureID, versionID) {
		const response = await fetch(`/api/authoredProcedures/${procedureID}/versions/${versionID}`);
		if (!response.ok) {
			throw new Error(`Failed to fetch procedure version ${response.statusText}`);
		}
		const procedure = await response.json();
		return procedure;
	}

	static async restoreProcedureVersion(procedureID, versionID) {
		const response = await fetch(`/api/authoredProcedures/${procedureID}/versions/${versionID}/restore`, {
			method: 'POST',
		});
		if (!response.ok) {
			throw new Error(`Failed to restore procedure version ${response.statusText}`);
		}
		const procedure = await response.json();
		return procedure;
	}

	static async fetchProcedureVersions(procedureID) {
		try {
			const url = `${this.#getProcedureUrl(procedureID)}/versions`;
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`Failed to fetch procedure versions: ${response.statusText}`);
			}
			const procedure = await response.json();
			return procedure;
		} catch (error) {
			console.error('Failed to fetch procedure versions:', error);
			return {};
		}
	}

	static initializeSocket(procedureID, actionProcessor) {
		const socket = io('/', {
			transports: ['websocket'],
		});

		socket.on('connect', () => {
			console.debug('🚀 Socket connected');
			socket.emit('joinProcedureRoom', procedureID);
		});

		socket.on('procedureUpdate', (data) => {
			console.debug('📥 Socket message:', data);
			actionProcessor(data);
		});

		socket.on('disconnect', () => {
			console.debug('👋 Socket disconnected');
		});

		socket.on('connect_error', (error) => {
			console.error('😬 Socket connection error:', error);
		});

		return socket;
	}

	static async sendAction(socket, procedureID, action) {
		socket.emit('procedureAction', { procedureID, action });
	}

	static async publishProcedure(procedureID) {
		const response = await fetch(`${this.#getProcedureUrl(procedureID)}/publish`, {
			method: 'POST',
		});

		if (!response.ok) {
			throw new Error(`Failed to publish procedure: ${response.statusText}`);
		}
		return response.json();
	}

	/**
	 * Uploads a file for a specific procedure.
	 * @param {string} procedureID - The ID of the procedure.
	 * @param {File} file - The file to upload.
	 * @returns {Promise<Object>} - The response from the backend containing the file URL.
	 */
	static async uploadProcedureFile(procedureID, file) {
		const formData = new FormData();
		formData.append('file', file);

		const response = await fetch(`/api/authoredProcedures/${procedureID}/upload-file`, {
			method: 'POST',
			body: formData,
		});

		if (!response.ok) {
			throw new Error(`Failed to upload file: ${response.statusText}`);
		}

		const result = await response.json();
		console.debug('📥 File uploaded successfully:', result);
		return result;
	}
}

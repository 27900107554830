import FigureInstruction from './FigureInstruction.vue';

class FigureInstructionPlugin {
	static #key = 'figureInstruction';

	static #initialModel = {
		figureURL: '',
		figureLabel: '',
	};

	static install(app) {
		app.component(FigureInstructionPlugin.#key, FigureInstruction);
		const { prideX } = app.config.globalProperties;
		const compositionAPI = prideX.getCompositionAPI();
		const componentDetails = {
			key: FigureInstructionPlugin.#key,
			model: FigureInstructionPlugin.#initialModel,
			parents: ['step', 'conditionalBlock.yes', 'conditionalBlock.no'],
			label: 'Figure Instruction',
		};
		compositionAPI.registerComponent(componentDetails);
	}
}

export default FigureInstructionPlugin.install;

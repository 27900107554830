<template>
	<div class="navbar">
		<Menubar :model="items" />
	</div>
</template>

<script setup>
import { ref } from 'vue';
import Menubar from 'primevue/menubar';

const items = ref([
	{ label: 'Home', icon: 'pi pi-fw pi-home', url: '/dashboard' },
	{ label: 'Print', icon: 'pi pi-fw pi-print', command: () => window.print() },
]);
</script>

<style scoped>
.pmenu-bar {
  border: 0px;
}
</style>

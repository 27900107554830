import { uuidv7 } from 'uuidv7';
import ConditionalBlock from './ConditionalBlock.vue';
import SubCondition from './SubCondition.vue';

class ConditionalBlockPlugin {
	static #key = 'conditionalBlock';

	static install(app) {
		app.component(ConditionalBlockPlugin.#key, ConditionalBlock);
		const { prideX } = app.config.globalProperties;
		const compositionAPI = prideX.getCompositionAPI();

		const yesKey = `${ConditionalBlockPlugin.#key}.yes`;
		const yesConditionalDetails = {
			key: yesKey,
			model: {
				title: 'Yes',
			},
			parents: [ConditionalBlockPlugin.#key],
			label: 'Yes',
		};
		compositionAPI.registerComponent(yesConditionalDetails);
		app.component(yesKey, SubCondition);

		const noKey = `${ConditionalBlockPlugin.#key}.no`;
		const noConditionalDetails = {
			key: noKey,
			model: {
				title: 'No',
			},
			parents: [ConditionalBlockPlugin.#key],
			label: 'No',
		};
		compositionAPI.registerComponent(noConditionalDetails);
		app.component(noKey, SubCondition);

		const initialModel = {
			criteria: {
				type: 'string',
				value: '',
			},
			children: [
				{
					type: yesKey,
					id: uuidv7(),
					children: [],
					...yesConditionalDetails.model,
				},
				{
					type: noKey,
					id: uuidv7(),
					children: [],
					...noConditionalDetails.model,
				},
			],
		};

		const parentConditionalDetails = {
			key: ConditionalBlockPlugin.#key,
			model: initialModel,
			parents: ['step'],
			label: 'Conditional Block',
		};
		compositionAPI.registerComponent(parentConditionalDetails);
	}
}

export default ConditionalBlockPlugin.install;

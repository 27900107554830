import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/themes/aura-light-green/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import Quill from 'quill';
import QuillCursors from 'quill-cursors';
import App from './App.vue';
import router from './router';
import PrideX from './api/PrideX';
import StepPlugin from './plugins/step/plugin';
import ManualInstructionPlugin from './plugins/manualInstruction/plugin';
import RecordInstructionPlugin from './plugins/recordInstruction/plugin';
import ConditionalBlockPlugin from './plugins/conditional/plugin';
import FigureInstructionPlugin from './plugins/figureInstruction/plugin';

const prideX = new PrideX();

Quill.register('modules/cursors', QuillCursors);

const app = createApp(App);

app.use(prideX);
app.use(PrimeVue);
app.directive('tooltip', Tooltip);
app.use(ToastService);
app.use(router);

// Register individual components here
app.use(StepPlugin);
app.use(ManualInstructionPlugin);
app.use(RecordInstructionPlugin);
app.use(ConditionalBlockPlugin);
app.use(FigureInstructionPlugin);

app.mount('#app');

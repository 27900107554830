<template>
	<div class="figure-instruction">
		<h3>{{ getNumberLabel() }}</h3>
		<label for="figureUpload">Upload Figure:</label>
		<FileUpload
			v-if="!figureURL"
			id="figureUpload"
			mode="basic"
			name="image"
			accept="image/*"
			choose-label="Choose Figure"
			custom-upload
			@select="onFileSelect"
		/>
		<Button
			v-if="figureURL"
			label="Remove Figure"
			icon="pi pi-times"
			class="p-button-danger remove-button"
			@click="removeImage"
		/>
		<label for="figureLabelInput">Figure Description:</label>
		<InputText
			id="figureLabelInput"
			v-model="figureLabel"
			variant="filled"
			placeholder="Describe the figure"
			class="w-full"
			@change="updateFigureLabel"
		/>
		<div
			v-if="figureURL"
			class="figure-preview"
		>
			<img
				:src="figureURL"
				alt="Uploaded Figure"
			>
		</div>
	</div>
</template>

<script setup>
import useProcedureElement, { procedureEmits, procedureProps } from '@/composables/useProcedureElement';
import { ref, watch, onMounted } from 'vue';
import ProcedureAPI from '@/api/ProcedureAPI';
import { useToast } from 'primevue/usetoast';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

const props = defineProps(procedureProps);
const emits = defineEmits(procedureEmits);

const figureURL = ref(props.model.figureURL);
const figureLabel = ref(props.model.figureLabel);
const toast = useToast();

const {
	procedureID,
	getNumberLabel,
	createAndEmitUpdateAction,
} = useProcedureElement(props, emits);

onMounted(() => {
	console.debug('🤖 FigureInstruction mounted with procedureID:', procedureID.value);
});

watch(() => props.model.figureURL, (newVal) => {
	figureURL.value = newVal;
});

watch(() => props.model.figureLabel, (newVal) => {
	figureLabel.value = newVal;
});

const onFileSelect = async (event) => {
	const file = event.files ? event.files[0] : event.target.files[0];
	if (file && file.type.startsWith('image/')) {
		try {
			const uploadedImage = await ProcedureAPI.uploadProcedureFile(procedureID.value, file);
			figureURL.value = uploadedImage.fileURL;
			createAndEmitUpdateAction('figureURL', figureURL.value);
		} catch (error) {
			console.error('Error uploading figure:', error);
			toast.add({
				severity: 'error',
				summary: 'Upload Error',
				detail: 'Failed to upload figure.',
				life: 3000,
			});
		}
	} else {
		toast.add({
			severity: 'warn',
			summary: 'Invalid File',
			detail: 'Please select a valid figure file.',
			life: 3000,
		});
	}
};

const removeImage = () => {
	figureURL.value = '';
	createAndEmitUpdateAction('figureURL', '');
};
const updateFigureLabel = () => {
	createAndEmitUpdateAction('figureLabel', figureLabel.value);
};
</script>

<style scoped>
.figure-instruction {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    height: 100%;
}

.figure-preview {
    margin-top: 10px;
}

.figure-preview img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    padding: 5px;
}

.remove-button {
				width: 10.3rem;
}
</style>

<template>
	<div>
		<div class="header p-p-2">
			<h1 class="p-mb-4 p-text-bold">
				Dashboard
			</h1>
			<Toolbar>
				<template #start>
					<Button
						label="Create"
						icon="pi pi-plus"
						class="p-button-success p-mr-4"
						@click="createProcedure"
					/>
					<Button
						label="Delete"
						icon="pi pi-trash"
						class="p-button-danger"
						:disabled="true"
					/>
				</template>
			</Toolbar>
		</div>
		<div class="cards-container p-mt-10">
			<Card
				v-for="procedure in procedures"
				:key="procedure.procedureID"
				class="procedure-card"
			>
				<template #title>
					<router-link
						:to="'/editor/' + procedure.procedureID"
						class="p-text-primary"
					>
						{{ procedure.title || 'Untitled Procedure' }}
					</router-link>
				</template>
				<template #subtitle>
					<div class="p-text-secondary">
						{{ procedure.number || 'No Number' }}
					</div>
				</template>
				<p class="p-text-secondary">
					{{ procedure.versionID }}
				</p>
				<p class="p-text-secondary">
					{{ procedure.updatedAt }}
				</p>
				<Button
					label="View Details"
					class="p-button-info"
					@click="viewDetails(procedure.procedureID)"
				/>
			</Card>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { generate } from 'random-words';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Toolbar from 'primevue/toolbar';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

const procedures = ref([]);
const router = useRouter();

onMounted(async () => {
	try {
		const response = await fetch('/api/authoredProcedures');
		procedures.value = await response.json();
	} catch (error) {
		console.error('🚨 Failed to fetch procedures:', error);
	}
});

function generateProcedureName() {
	return generate({ wordsPerString: 2, minLength: 5, exactly: 1 })[0];
}

function getRandomFourDigitNumber() {
	return Math.floor(1000 + Math.random() * 9000); // Generates a number between 1000 and 9999
}

const createProcedure = async () => {
	const title = generateProcedureName();
	const number = getRandomFourDigitNumber();
	try {
		const response = await fetch('/api/authoredProcedures', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ title, number }), // Include the title and number in the request body
		});
		const newProcedure = await response.json();
		if (response.ok) {
			router.push(`/editor/${newProcedure.procedureID}`);
		} else {
			console.error('Failed to create procedure:', response);
		}
	} catch (error) {
		console.error('🚨 Error during creation:', error);
	}
};

const viewDetails = (procedureID) => {
	router.push(`/editor/${procedureID}`);
};
</script>

<style scoped>
.cards-container {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: flex-start; /* Align items from the left */
	align-items: flex-start;
	margin-top: 16px; /* Added margin to push down from toolbar */
}

.procedure-card {
	border: 1px solid var(--surface-d, #e0e0e0);
	background-color: var(--surface-b, #ffffff);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	width: 300px;
	cursor: pointer;
	transition: transform 0.3s ease;
}

.procedure-card:hover {
	transform: scale(1.05);
}
</style>

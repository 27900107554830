<template>
	<div class="conditional-block">
		<h3>{{ getNumberLabel() }}</h3>
		<label for="dataItemInput">Condition:</label>
		<InputText
			id="conditionId"
			v-model="conditionName"
			variant="filled"
			placeholder="Enter a condition"
			@change="createAndEmitUpdateAction('conditionName', conditionName)"
		/>
		<div class="children">
			<div
				v-for="(child, index) in model.children"
				:key="index"
				class="children-wrapper"
			>
				<component
					:is="child.type"
					:model="child"
					:number="`${index + 1}`"
					:parent-number="getNumberLabel()"
					:path="`${path}/children/${index}`"
					:username="username"
					:version-viewer="versionViewer"
					:procedure-i-d="procedureID"
					@insert-child="insertChild"
					@update-child="updateChild"
					@selection-on-child="selectionOnChild"
				/>
			</div>
		</div>
	</div>
</template>

<script setup>

import useProcedureElement, { procedureEmits, procedureProps } from '@/composables/useProcedureElement';
import InputText from 'primevue/inputtext';

import {
	ref,
	watch,
} from 'vue';

const props = defineProps(procedureProps);
const emits = defineEmits(procedureEmits);

const conditionName = ref(props.model.conditionName);

watch(() => props.model.conditionName, (newVal) => {
	conditionName.value = newVal;
});

const {
	getNumberLabel,
	createAndEmitUpdateAction,
	insertChild,
	updateChild,
	selectionOnChild,
	procedureID,
} = useProcedureElement(props, emits);

</script>

<style scoped>
.conditional-block {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  height: 100%;
}
</style>

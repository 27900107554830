import StepComponent from './StepComponent.vue';

class StepPlugin {
	static #key = 'step';

	static #initialModel = {
		// add new title with current time
		title: `New Step ${new Date().toLocaleTimeString()}`,
	};

	static install(app) {
		app.component(StepPlugin.#key, StepComponent);
		const { prideX } = app.config.globalProperties;
		const compositionAPI = prideX.getCompositionAPI();
		compositionAPI.registerComponent({
			key: StepPlugin.#key,
			model: StepPlugin.#initialModel,
			parents: ['procedure', 'step'],
			label: 'Step',
		});
	}
}

export default StepPlugin.install;

import { createRouter, createWebHistory } from 'vue-router';
import DashboardComponent from '@/components/DashboardComponent.vue';
import ProcedureEditor from '@/components/ProcedureEditor.vue';
import LoginComponent from '@/components/LoginComponent.vue';

const routes = [
	{ path: '/', component: LoginComponent },
	{ path: '/dashboard', component: DashboardComponent },
	{ path: '/editor/:procedureID', component: ProcedureEditor },
	{ path: '/editor/:procedureID/version/:versionID', component: ProcedureEditor },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;

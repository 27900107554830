<template>
	<div>
		<Button
			icon="pi pi-plus"
			class="p-button-rounded p-button-info"
			:disabled="isDisabled"
			@click="checkForModal"
		/>
		<Dialog
			v-model:visible="showModal"
			header="Select Child Type"
			:modal="true"
			:draggable="false"
		>
			<div class="flex flex-wrap justify-center gap-2">
				<Button
					v-for="option in childOptions"
					:key="option.key"
					:label="option.label"
					class="p-button-outlined"
					@click="selectChildType(option.key)"
				/>
			</div>
			<div class="flex justify-end gap-2 mt-4">
				<Button
					label="Cancel"
					@click="showModal = false"
				/>
			</div>
		</Dialog>
	</div>
</template>

<script setup>
import {
	ref, inject, onMounted,
} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog'; // Import Dialog

const props = defineProps({
	parentType: {
		type: String,
		required: true,
	},
	isDisabled: {
		type: Boolean,
		default: false,
	},
	username: {
		type: String,
		required: true,
	},
	path: {
		type: String,
		default: '',
	},
	model: {
		type: Object,
		required: true,
	},
});

const selectedChildType = ref(null);
const childOptions = ref(null);
const compositionAPI = inject('prideX').getCompositionAPI();
const emit = defineEmits(['insertChild']);
const showModal = ref(false);

const handleAdd = async () => {
	// Check if a child type has been selected
	if (!selectedChildType.value) {
		return;
	}

	// Get the initial model for the selected child type
	const rawChildModel = compositionAPI.getInitialModel(selectedChildType.value);
	if (!rawChildModel) {
		return;
	}
	const childModel = ref(rawChildModel).value;

	// Calculate the new child's path
	const childLength = props.model.children.length || 0;
	const pathToAdd = `${props.path}/children/${childLength}`;

	// Create the action object for the new child
	const action = {
		patch: {
			op: 'add',
			path: pathToAdd,
			value: childModel,
		},
		metadata: {
			author: props.username,
			clientCreationAt: new Date().toISOString(),
		},
	};

	// Emit the action to the parent component or Vuex store
	emit('insertChild', action);

	// Optionally reset the selectedChildType or close modal
	selectedChildType.value = null;
	showModal.value = false;
};

const selectChildType = (type) => {
	selectedChildType.value = type;
	showModal.value = false;
	handleAdd();
};

const checkForModal = () => {
	if (childOptions.value.length > 1) {
		showModal.value = true;
	} else {
		selectChildType(childOptions.value[0].key);
	}
};

onMounted(() => {
	childOptions.value = compositionAPI.getPossibleChildren(props.parentType).map((key) => ({
		label: compositionAPI.getLabel(key),
		key,
	}));
});
</script>

import RecordInstruction from './RecordInstruction.vue';

class RecordInstructionPlugin {
	static #key = 'recordInstruction';

	static #initialModel = {
		dataItemName: '',
		dataType: 'string',
	};

	static install(app) {
		app.component(RecordInstructionPlugin.#key, RecordInstruction);
		const { prideX } = app.config.globalProperties;
		const compositionAPI = prideX.getCompositionAPI();
		const componentDetails = {
			key: RecordInstructionPlugin.#key,
			model: RecordInstructionPlugin.#initialModel,
			parents: ['step', 'conditionalBlock.yes', 'conditionalBlock.no'],
			label: 'Record Instruction',
		};
		compositionAPI.registerComponent(componentDetails);
	}
}

export default RecordInstructionPlugin.install;

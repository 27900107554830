<template>
	<div class="main-wrapper">
		<Navbar />
		<Toast>
			<template #message="{ message }">
				<!-- eslint-disable-next-line vue/no-v-html -->
				<div v-html="message.detail" />
			</template>
		</Toast>
		<div class="header-content">
			<div class="title-publish-container">
				<div class="title-buttons-container">
					<h1>{{ model.number }}: {{ model.title }}</h1>
					<AddChildButton
						:is-disabled="versionViewer"
						:username="DEBUG_USERNAME"
						:model="model.content"
						parent-type="procedure"
						@insert-child="handleInsertChild"
					/>
				</div>
				<Button
					v-if="!versionViewer"
					label="Publish"
					class="p-button-success publish-button"
					:disabled="inProcessOfPublishing"
					@click="publishProcedure"
				/>
				<Button
					v-if="versionViewer"
					label="Restore this version"
					class="p-button-rounded p-button-warning restore-button"
					:disabled="inProcessOfPublishing"
					@click="restoreThisVersion"
				/>
			</div>
			<div class="version-info">
				<h5>Last published on {{ formattedDate }} by {{ model.publisher }}</h5>
				<Button
					v-tooltip="'View version history'"
					icon="pi pi-clock"
					class="p-button-rounded p-button-info versions-button"
					@click="toggleVersionsSidebar"
				/>
			</div>
			<Sidebar
				v-model:visible="versionsSidebarVisible"
				position="right"
				:modal="true"
				:class="'version-sidebar'"
				:style="{ width: '800px' }"
			>
				<ProcedureVersions :procedure-id="model.procedureID" />
			</Sidebar>
		</div>
		<div class="children">
			<div
				v-for="(child, index) in model.content?.children"
				:key="index"
				class="children-wrapper"
			>
				<component
					:is="child.type"
					:model="child"
					:number="`${index + 1}`"
					:path="`/children/${index}`"
					:username="DEBUG_USERNAME"
					:version-viewer="versionViewer"
					:procedure-i-d="procedureID"
					@insert-child="handleInsertChild"
					@update-child="handleUpdateChild"
					@selection-on-child="handleSelectionOnChild"
				/>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	ref, onBeforeUnmount, computed, onMounted, inject,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import Sidebar from 'primevue/sidebar';
import { generate } from 'random-words';
import Navbar from '@/components/NavBar.vue';
import ProcedureVersions from '@/components/ProcedureVersions.vue';
import { immutableJSONPatch } from 'immutable-json-patch';
import AddChildButton from '@/components/AddChildButton.vue';

const toast = useToast();
const router = useRouter();
const route = useRoute();
const ProcedureAPI = inject('prideX').getProcedureAPI();

const model = ref({
	number: '',
	title: '',
	createdAt: '',
	publisher: '',
	content: {},
	authors: [],
});

const versionViewer = ref(false);
const procedureID = ref(route.params.procedureID);

const inProcessOfPublishing = ref(false);

const versionsSidebarVisible = ref(false);

const DEBUG_USERNAME = `user_${generate({
	wordsPerString: 1,
	exactly: 1,
	minLength: 6,
})}`;

let removeRouterNavigationListener = null;
let socket = null;

onMounted(async () => {
	fetchModel(route.params);
	removeRouterNavigationListener = router.beforeEach(checkRoute);
});

async function checkRoute(to, from, next) {
	// if procedureID or versionID is different, fetch the model
	if (
		to.params.procedureID !== route.params.procedureID
    || to.params.versionID !== route.params.versionID
	) {
		console.debug(
			`🚦 Fetching model for ${route.params.procedureID} and ${to.params.versionID}`,
		);
		await fetchModel(to.params);
	}
	next();
}

onBeforeUnmount(() => {
	socket?.disconnect();
	if (removeRouterNavigationListener) {
		removeRouterNavigationListener();
	}
});

async function restoreThisVersion() {
	try {
		inProcessOfPublishing.value = true;
		await ProcedureAPI.restoreProcedureVersion(
			route.params.procedureID,
			route.params.versionID,
		);
		// now route to the editor page
		router.push(`/editor/${route.params.procedureID}`);
		versionViewer.value = false;
		toast.add({
			severity: 'success',
			summary: 'Success',
			detail: 'Procedure restored successfully!',
		});
	} catch (e) {
		toast.add({
			severity: 'error',
			summary: 'Error',
			detail: e.message || 'An error occurred while restoring the procedure',
		});
	} finally {
		inProcessOfPublishing.value = false;
	}
}

const formattedDate = computed(() => new Date(model.value.createdAt).toLocaleString());

async function fetchModel(params) {
	socket?.disconnect();
	socket = ProcedureAPI.initializeSocket(
		route.params.procedureID,
		processAction,
	);

	if (params.versionID) {
		versionViewer.value = true;
		model.value = await ProcedureAPI.fetchProcedureVersion(
			params.procedureID,
			params.versionID,
		);
	} else {
		const fetchedProcedure = await ProcedureAPI.fetchProcedureDetails(params.procedureID);
		model.value = fetchedProcedure;
	}
	processInitialProcedure();
}

function toggleVersionsSidebar() {
	versionsSidebarVisible.value = !versionsSidebarVisible.value;
}

function handleInsertChild(action) {
	processAction(action, false);
	ProcedureAPI.sendAction(socket, route.params.procedureID, action);
}

function handleSelectionOnChild(action) {
	processAction(action);
	ProcedureAPI.sendAction(socket, route.params.procedureID, action);
}

function handleUpdateChild(action) {
	processAction(action);
	ProcedureAPI.sendAction(socket, route.params.procedureID, action);
}

function processInitialProcedure() {
	const patches = model.value.loadedActions?.map((action) => action.patch);
	if (!patches) {
		return;
	}
	const newContent = immutableJSONPatch(model.value.content, patches);
	model.value.content = newContent;
}

function processAction(action, ignoreOwnActions = true) {
	// test if action is valid
	let newContent;
	try {
		newContent = immutableJSONPatch(model.value.content, [action.patch]);
	} catch (e) {
		throw new Error(`❌ Invalid action (${e.message}) was received for op: ${action?.patch?.op} path: ${action?.patch?.path} value: ${JSON.stringify(action?.patch?.value)}`);
	}
	if (ignoreOwnActions && action.metadata.author === DEBUG_USERNAME) {
		return;
	}
	model.value.content = newContent;
}

async function publishProcedure() {
	try {
		inProcessOfPublishing.value = true;
		const publishedInfo = await ProcedureAPI.publishProcedure(
			route.params.procedureID,
		);
		// reload the procdure object
		fetchModel(route.params);
		toast.add({
			severity: 'success',
			summary: 'Success',
			detail: `Procedure published successfully!<br> <a href="${publishedInfo.linkToProcedure}" target="_blank">View Procedure</a>`,
		});
	} catch (e) {
		toast.add({
			severity: 'error',
			summary: 'Error',
			detail: e.message || 'An error occurred while publishing the procedure',
		});
	} finally {
		inProcessOfPublishing.value = false;
	}
}
</script>
<style scoped>
.main-wrapper {
  padding: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title-publish-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.title-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.publish-button {
  margin-right: 2rem;
}

.restore-button {
  margin-right: 2rem;
}

.version-info {
  display: flex;
  align-items: center;
}

.versions-button {
  margin-left: 1rem;
}
</style>

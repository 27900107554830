import ManualInstruction from './ManualInstruction.vue';

class ManualInstructionPlugin {
	static #key = 'manualInstruction';

	static #initialModel = {
		text: `New Instruction ${new Date().toLocaleTimeString()}`,
	};

	static install(app) {
		app.component(ManualInstructionPlugin.#key, ManualInstruction);
		const { prideX } = app.config.globalProperties;
		const compositionAPI = prideX.getCompositionAPI();
		const componentDetails = {
			key: ManualInstructionPlugin.#key,
			model: ManualInstructionPlugin.#initialModel,
			parents: ['step', 'conditionalBlock.yes', 'conditionalBlock.no'],
			label: 'Manual Instruction',
		};
		compositionAPI.registerComponent(componentDetails);
	}
}

export default ManualInstructionPlugin.install;

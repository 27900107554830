<template>
	<div class="record-instruction">
		<h3>{{ getNumberLabel() }}</h3>
		<label for="dataItemInput">Name:</label>
		<InputText
			id="dataItemInput"
			v-model="dataItemName"
			variant="filled"
			placeholder="Data Item Name"
			@change="createAndEmitUpdateAction('dataItemName', dataItemName)"
		/>
		<label for="dataTypeInput">Data Type:</label>
		<Dropdown
			v-model="dataType"
			:options="inputTypes"
			placeholder="Select a data type"
			class="w-full md:w-14rem"
			@change="createAndEmitUpdateAction('dataType', dataType)"
		/>
	</div>
</template>

<script setup>

import useProcedureElement, { procedureEmits, procedureProps } from '@/composables/useProcedureElement';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import {
	ref,
	watch,
} from 'vue';

const props = defineProps(procedureProps);
const emits = defineEmits(procedureEmits);

const dataItemName = ref(props.model.dataItemName);
const dataType = ref(props.model.dataType);

watch(() => props.model.dataItemName, (newVal) => {
	dataItemName.value = newVal;
});

watch(() => props.model.dataType, (newVal) => {
	dataType.value = newVal;
});

const {
	getNumberLabel,
	createAndEmitUpdateAction,
} = useProcedureElement(props, emits);

const inputTypes = ['real', 'integer', 'string', 'boolean', 'duration', 'dateTime', 'json'];

</script>

<style scoped>
.record-instruction {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  height: 100%;
}
</style>

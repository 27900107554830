import CompositionAPI from './CompositionAPI';
import ProcedureAPI from './ProcedureAPI';

export default class PrideX {
	#compositionAPI;

	constructor() {
		this.#compositionAPI = new CompositionAPI();
	}

	install(app) {
		// eslint-disable-next-line no-param-reassign
		app.config.globalProperties.prideX = this;
		app.provide('prideX', this);
	}

	getCompositionAPI() {
		return this.#compositionAPI;
	}

	// eslint-disable-next-line class-methods-use-this
	getProcedureAPI() {
		return ProcedureAPI;
	}
}

<template>
	<div class="manual-instruction">
		<div class="manual-instruction-header">
			<h3>{{ getNumberLabel() }}</h3>
		</div>
		<div
			ref="editorContainer"
			class="editorContainer"
		/>
	</div>
</template>

<script setup>
import {
	ref, onMounted, watch, computed,
} from 'vue';
import { debounce, isEqual } from 'lodash';
import Quill from 'quill';
import useProcedureElement, { procedureEmits, procedureProps } from '@/composables/useProcedureElement';

const props = defineProps(procedureProps);
const emits = defineEmits(procedureEmits);

const {
	model,
	path,
	username,
	versionViewer,
	getNumberLabel,
	createAndEmitUpdateAction,
	createAndEmitSelectionAction,
} = useProcedureElement(props, emits);

// Constants for debounce timings
const TEXT_DEBOUNCE_TIME = 200;
const SELECTION_DEBOUNCE_TIME = 200;

// Refs and variables
const editorContainer = ref(null);
let quill;
const userCursors = {};
let lastEmittedSelection = {};

// Function to emit selection changes
function emitSelectionChange(range) {
	let startSelectionPosition = null;
	let endSelectionPosition = null;
	if (range) {
		startSelectionPosition = range.index;
		endSelectionPosition = range.index + range.length;
	} else {
		return;
	}
	const newSelectionEventToEmit = {
		path,
		startSelectionPosition,
		endSelectionPosition,
		username,
	};
	// Don't fire if the selection hasn't changed
	if (isEqual(lastEmittedSelection, newSelectionEventToEmit)) return;
	createAndEmitSelectionAction(newSelectionEventToEmit);
	lastEmittedSelection = newSelectionEventToEmit;
}

// Debounced handlers
const textChangedHandler = debounce((delta, oldContents, source) => {
	if (source === 'api') return;
	const quillContent = quill.getText();
	createAndEmitUpdateAction('text', quillContent);
}, TEXT_DEBOUNCE_TIME);

const selectionChangeHandler = debounce((range) => {
	emitSelectionChange(range);
}, SELECTION_DEBOUNCE_TIME);

const text = computed(() => model.value.text);

watch(
	text,
	(newText) => {
		if (quill.getText() === newText) return;
		const currentSelection = quill.getSelection();
		quill.setText(newText, 'api');
		if (currentSelection) {
			quill.setSelection(currentSelection.index, currentSelection.length, 'api');
		}
	},
);

const selection = computed(() => model.value.selection);

watch(
	() => selection,
	(newSelection) => {
		if (newSelection.value && newSelection.value.author !== username.value) {
			const cursorModule = quill.getModule('cursors');
			if (!userCursors[newSelection.value.author]) {
				const newCursor = cursorModule.createCursor(
					newSelection.value.author,
					newSelection.value.author,
					'purple',
				);
				newCursor.toggleFlag(newSelection.value.author, true);
				userCursors[newSelection.value.author] = newCursor;
			}
			if (newSelection.value.startSelectionPosition === null) {
				cursorModule.removeCursor(newSelection.value.author);
				delete userCursors[newSelection.value.author];
			} else {
				cursorModule.moveCursor(newSelection.value.author, {
					index: newSelection.value.startSelectionPosition,
					length:
            newSelection.value.endSelectionPosition
            - newSelection.value.startSelectionPosition,
				});
			}
		}
	},
	{ deep: true },
);

onMounted(() => {
	quill = new Quill(editorContainer.value, {
		modules: {
			cursors: true,
			toolbar: versionViewer.value
				? false
				: ['bold', 'italic', 'underline', 'strike'],
			// Add other modules as needed
		},
		theme: 'snow',
	});

	if (versionViewer.value) {
		quill.disable();
	} else {
		quill.on('text-change', textChangedHandler);
		quill.on('selection-change', selectionChangeHandler);
	}

	quill.setText(`${model.value.text}`, 'api');
});
</script>

<style scoped>
.manual-instruction {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  height: 100%;
}
.manual-instruction-header {
  margin-bottom: 10px;
  /* Spacing between the header and the editor */
}
.editorContainer {
  height: 100%;
}
</style>

import { uuidv7 } from 'uuidv7';

export default class CompositionAPI {
	#allowedParents;

	#initialModels;

	#labels;

	constructor() {
		this.#allowedParents = {};
		this.#initialModels = {};
		this.#labels = {};
	}

	registerComponent({
		key, model, parents, label,
	}) {
		this.#registerInitialModel(key, model);
		parents.forEach((parent) => this.#allowParent(key, parent));
		this.#registerLabel(key, label);
	}

	#allowParent(child, parent) {
		if (!this.#allowedParents[child]) {
			this.#allowedParents[child] = [];
		}
		this.#allowedParents[child].push(parent);
	}

	getPossibleChildren(parent) {
		// eslint-disable-next-line max-len
		return Object.keys(this.#allowedParents).filter((child) => this.#allowedParents[child].includes(parent));
	}

	#registerInitialModel(key, model) {
		const normalizedModel = {
			children: [],
			type: key,
			...model,
		};
		this.#initialModels[key] = normalizedModel;
	}

	#registerLabel(key, label) {
		this.#labels[key] = label;
	}

	getLabel(key) {
		return this.#labels[key];
	}

	getInitialModel(key) {
		const initialModel = this.#initialModels[key];
		initialModel.id = uuidv7();
		initialModel.selection = null;
		return this.#initialModels[key];
	}
}

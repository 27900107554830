<template>
	<div class="subCondition">
		<div class="subCondition-header">
			<h3>{{ getNumberLabel() }}<span v-if="parentNumber === null">.</span> {{ model.title }}</h3>
			<div class="title-publish-container">
				<AddChildButton
					:is-disabled="versionViewer"
					:username="username"
					:model="model"
					:path="path"
					parent-type="step"
					@insert-child="insertChild"
				/>
			</div>
		</div>
		<div class="children">
			<div
				v-for="(child, index) in model.children"
				:key="index"
				class="children-wrapper"
			>
				<component
					:is="child.type"
					:model="child"
					:number="`${index + 1}`"
					:parent-number="getNumberLabel()"
					:path="`${path}/children/${index}`"
					:username="username"
					:version-viewer="versionViewer"
					:procedure-i-d="procedureID"
					@insert-child="insertChild"
					@update-child="updateChild"
					@selection-on-child="selectionOnChild"
				/>
			</div>
		</div>
	</div>
</template>

<script setup>
import useProcedureElement, { procedureEmits, procedureProps } from '@/composables/useProcedureElement';
import AddChildButton from '@/components/AddChildButton.vue';
import { onMounted } from 'vue';

const props = defineProps(procedureProps);
const emits = defineEmits(procedureEmits);

const {
	model,
	path,
	parentNumber,
	username,
	versionViewer,
	getNumberLabel,
	insertChild,
	updateChild,
	selectionOnChild,
	procedureID,
} = useProcedureElement(props, emits);

onMounted(() => {
	console.debug('🤖 SubCondition mounted', model);
});

</script>

<style scoped>
.subCondition {
  padding: 20px;
}
.subCondition-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.title-buttons-container {
	margin-left: 70px;
}
</style>
